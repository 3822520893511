/* eslint-disable no-else-return */
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    CartItemType,
    CartTableType,
    ItemType,
    ProductOption,
} from './cartType';
import { useSearchParams } from 'react-router-dom';
import ModalConfirm from 'components/modal/ModalConfirm';
import { App } from 'antd';
import _ from 'lodash';

// Định nghĩa loại dữ liệu cho sản phẩm trong giỏ hàng

// Định nghĩa loại dữ liệu cho Context của giỏ hàng
interface CartContextType {
    indexTable: number;
    cartItems: CartTableType[];
    addCart: (item: any) => void;
    addToCart(item: ItemType): void;
    updateQuantityItemFromCart: ({
        index,
        type,
        value,
    }: {
        index: number;
        type: 'decrea' | 'increa';
        value?: number;
    }) => void;
    // clearCart: () => void;
    setSelectedCart: any;
    selectedCart: number;
    setCustomerName: (
        name: string,
        cartIndex: number,
        indexTable: number,
        numberOfCustomer?: number,
        phonenumber?: string,
    ) => void;
    updateCartIndex: (cart: CartItemType, index?: number) => void;
    removeCartIndex: (index?: number) => void;
    removeItemFromCart: (sku: string) => void;
    InputNoteItemFromCart: (index: number, note: string) => void;
    updateProductModifierItemFromCart: (
        index: number,
        productOptions: ProductOption[],
        price: number,
    ) => void;
    InputNoteItemBundleFromCart: (
        index: number,
        note: string | any,
        bundleIndex: number,
    ) => void;
    onRemoveItem: (index: number) => void;
    customOpenPriceForItem: ({
        index,
        custom_price,
    }: {
        index: number;
        custom_price: number;
    }) => void;
    onRemoveItemWithoutConfirm: (index: number) => void;
    UpdateGuestInfoOfCart: (input: {
        name: string;
        note: string;
        phone: string;
        numberOfCustomer: number;
        serve_name: string;
    }) => void;
    cartIndex: number | string;
    tableId: string;
    NewSeat: () => void;
    RemoveSeat: (seatNumber: number) => void;
    seat: number;
    setSeat: any;
    group: string;
    setGroup: React.Dispatch<React.SetStateAction<string>>;
    setListSeat: (seats: number[]) => void;
}

// Tạo Context cho giỏ hàng
const CartContext = createContext<CartContextType | undefined>(undefined);
// Custom hook để sử dụng CartContext
export const useCart = (): CartContextType => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

// Component cha chứa dữ liệu giỏ hàng và cung cấp thông tin qua Context Provider
export const CartProvider = ({ children }: { children: React.ReactNode }) => {
    const [cartItems, setCartItems] = useState<CartTableType[]>([]);
    const [indexTable, setIndexTable] = useState(-1);
    const [selectedCart, setSelectedCart] = useState(0);
    const [searchParams] = useSearchParams();
    const tableId = searchParams.get('tableId') || '0';
    const cartIndex = searchParams.get('cartIndex') || '0';
    const [seat, setSeat] = useState<number>(1);
    const [group, setGroup] = useState('');
    const { notification } = App.useApp();
    useEffect(() => {
        const getIndexTable = cartItems.findIndex(
            (item) => item.tableId == tableId,
        );
        setIndexTable(getIndexTable);
    }, [tableId, cartItems]);
    const addCart = (item: CartItemType[]) => {
        setCartItems((prevCartItems) => {
            const newCartItems = [...prevCartItems];
            const tableId = searchParams.get('tableId') || '0';
            const indexTable = prevCartItems.findIndex(
                (item) => item.tableId == tableId,
            );

            if (indexTable == -1) {
                const newCart = item.map((currentCart) =>
                    calcCanceled(currentCart),
                );

                newCartItems.push({
                    tableId: tableId,
                    carts: newCart,
                });
            } else {
                const newCart = item.map((currentCart) =>
                    calcCanceled(currentCart),
                );

                updateCart(newCart, indexTable);
            }

            return newCartItems;
        });
    };
    const calcCanceled = (currentCart: CartItemType) => {
        {
            const Tax =
                (currentCart?.prices?.applied_taxes?.[0]?.tax_percent || 0) /
                100;
            const itemsCanceled = currentCart?.items?.filter((item) => {
                return item?.status === 'cancel';
            });
            const total_canceled_without_tax = itemsCanceled?.reduce(
                (total, item) => {
                    return total + item.prices.price?.value * item.quantity;
                },
                0,
            );
            const total_canceled = total_canceled_without_tax * (Tax + 1);
            const total_items_canceled_discount = itemsCanceled?.reduce(
                (total, item) => {
                    return (
                        total +
                        (item.prices.total_item_discount?.value ||
                            0 * item.quantity)
                    );
                },
                0,
            );
            return {
                ...currentCart,
                prices: {
                    ...currentCart?.prices,
                    total_canceled: {
                        value: total_canceled,
                    },
                    total_canceled_without_tax: {
                        value: total_canceled_without_tax,
                    },
                    total_items_canceled_discount: {
                        value: total_items_canceled_discount,
                    },
                },
            };
        }
    };
    const updateCartIndex = (cart: CartItemType, index?: number) => {
        const getIndexTable = cartItems.findIndex(
            (item) => item.tableId == tableId,
        );
        const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
        const newCartItems = [...cartItems];
        newCartItems[getIndexTable].carts[
            index && index >= 0 ? index : cartIndex
        ] = cart;
        const uniqueCart = _.uniqBy(newCartItems[getIndexTable].carts, 'id');
        updateCart(uniqueCart, getIndexTable, true);
    };
    const setListSeat = (seats: number[]) => {
        try {
            const getIndexTable = cartItems.findIndex(
                (item) => item.tableId == tableId,
            );
            const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
            const newCartItems = [...cartItems];
            newCartItems[getIndexTable].carts[cartIndex] = {
                ...newCartItems[getIndexTable].carts[cartIndex],
                seatList: seats,
            };
            const uniqueCart = _.uniqBy(
                newCartItems[getIndexTable].carts,
                'id',
            );
            updateCart(uniqueCart, getIndexTable, true);
        } catch (error) {
            console.log(error);
        }
    };
    const NewSeat = () => {
        try {
            // Lấy chỉ số của tableId trong cartItems
            const getIndexTable = cartItems.findIndex(
                (item) => item.tableId === tableId,
            );

            // Kiểm tra hợp lệ
            if (getIndexTable === -1) {
                throw new Error(`Table ID ${tableId} not found in cartItems.`);
            }

            const cartIndex = parseInt(searchParams.get('cartIndex') || '0');

            // Kiểm tra cartIndex hợp lệ
            const tableCarts = cartItems[getIndexTable]?.carts;
            if (
                !tableCarts ||
                cartIndex < 0 ||
                cartIndex >= tableCarts.length
            ) {
                throw new Error(
                    `Invalid cartIndex ${cartIndex} for table ID ${tableId}.`,
                );
            }

            // Tạo bản sao cartItems
            const newCartItems = [...cartItems];
            const currentListSeat =
                newCartItems[getIndexTable]?.carts[cartIndex]?.seatList || [];

            // Xác định giá trị lớn nhất hiện có và thêm ghế mới
            const nextSeatNumber = currentListSeat.length
                ? Math.max(...currentListSeat) + 1
                : 1;
            currentListSeat.push(nextSeatNumber);

            // Cập nhật lại danh sách ghế
            newCartItems[getIndexTable].carts[cartIndex].seatList =
                currentListSeat;

            // Cập nhật cart
            updateCart(newCartItems[getIndexTable].carts, getIndexTable, true);
        } catch (error) {
            console.error('Error in NewSeat:', error);
        }
    };

    const RemoveSeat = (seatNumber: number) => {
        try {
            const getIndexTable = cartItems.findIndex(
                (item) => item.tableId === tableId,
            );

            if (getIndexTable === -1) {
                throw new Error(`Table ID ${tableId} not found in cartItems.`);
            }

            const cartIndex = parseInt(searchParams.get('cartIndex') || '0');

            const tableCarts = cartItems[getIndexTable]?.carts;
            if (
                !tableCarts ||
                cartIndex < 0 ||
                cartIndex >= tableCarts.length
            ) {
                throw new Error(
                    `Invalid cartIndex ${cartIndex} for table ID ${tableId}.`,
                );
            }

            const newCartItems = [...cartItems];
            const currentListSeat =
                newCartItems[getIndexTable]?.carts[cartIndex]?.seatList || [];

            const updatedListSeat = currentListSeat.filter(
                (item) => item !== seatNumber,
            );

            newCartItems[getIndexTable].carts[cartIndex].seatList =
                updatedListSeat;

            updateCart(newCartItems[getIndexTable].carts, getIndexTable, true);
        } catch (error) {
            console.error('Error in RemoveSeat:', error);
        }
    };

    const removeCartIndex = (index?: number) => {
        const getIndexTable = cartItems.findIndex(
            (item) => item.tableId == tableId,
        );
        const cartIndex =
            index !== undefined
                ? index
                : parseInt(searchParams.get('cartIndex') || '0', 10);

        if (getIndexTable !== -1) {
            const newCartItems = [...cartItems];
            const updatedCart = { ...newCartItems[getIndexTable] };
            // Xóa phần tử có index là cartIndex từ mảng carts
            updatedCart.carts.splice(cartIndex, 1);

            const listCarts = updatedCart.carts.filter((item) => {
                return item.id !== undefined && item.id !== null;
            });
            // Cập nhật lại mảng cartItems với phần tử được cập nhật
            newCartItems[getIndexTable] = {
                ...updatedCart,
                carts: listCarts,
            };
            setCartItems(newCartItems);
        }
    };
    const updateCart = (
        cartItemNew: CartItemType[],
        indexTable: number,
        isUpdate?: boolean,
    ) => {
        setCartItems((prevCartItems) => {
            const currentCartItems = [...prevCartItems];
            const result = currentCartItems[indexTable]?.carts?.map(
                (currentCart, index) => {
                    const itemsIsUnSend = currentCart?.items?.filter((item) => {
                        return item?.isUnsend;
                    });
                    const newCarts = cartItemNew.find((itemNew) => {
                        return itemNew.id === currentCart.id;
                    });
                    const itemsCanceled =
                        cartItemNew[index]?.items?.filter((item) => {
                            return item?.status === 'cancel';
                        }) ||
                        currentCart?.items?.filter((item) => {
                            return item?.status === 'cancel';
                        });
                    const Tax =
                        (currentCart?.prices?.applied_taxes?.[0]?.tax_percent ||
                            0) / 100;

                    const total_itemsCanceled_without_tax =
                        itemsCanceled?.reduce((total, item) => {
                            return (
                                total + item.prices.price?.value * item.quantity
                            );
                        }, 0);
                    const total_itemsCanceled =
                        total_itemsCanceled_without_tax * (Tax + 1);
                    const total_items_canceled_discount = itemsCanceled?.reduce(
                        (total, item) => {
                            return (
                                total +
                                (item.prices.total_item_discount?.value ||
                                    0 * item.quantity)
                            );
                        },
                        0,
                    );

                    if (newCarts) {
                        if (newCarts?.items && newCarts?.items.length >= 0) {
                            return {
                                ...newCarts,
                                items: isUpdate
                                    ? newCarts?.items
                                    : [...newCarts.items, ...itemsIsUnSend],
                                prices: {
                                    ...newCarts?.prices,
                                    grand_total: {
                                        value:
                                            (newCarts?.prices?.grand_total
                                                ?.value || 0) +
                                            itemsIsUnSend.reduce(
                                                (total, item) => {
                                                    return (
                                                        total +
                                                        item.prices.price
                                                            ?.value *
                                                            item.quantity
                                                    );
                                                },
                                                0,
                                            ),
                                    },
                                    total_canceled: {
                                        value: total_itemsCanceled,
                                    },
                                    total_canceled_without_tax: {
                                        value: total_itemsCanceled_without_tax,
                                    },
                                    total_items_canceled_discount: {
                                        value: total_items_canceled_discount,
                                    },
                                },
                            };
                        }
                        return {
                            ...newCarts,
                            prices: {
                                ...newCarts?.prices,
                                total_canceled: {
                                    value: total_itemsCanceled,
                                },
                                total_canceled_without_tax: {
                                    value: total_itemsCanceled_without_tax,
                                },
                                total_items_canceled_discount: {
                                    value: total_items_canceled_discount,
                                },
                            },
                        };
                    }

                    return {
                        ...currentCart,
                    };
                },
            );

            // Kiểm tra xem cart này đã có tồn tại hay chưa , nếu chưa thì thêm mới
            cartItemNew.forEach((newItem) => {
                if (!result.find((item) => item.id === newItem.id)) {
                    result.push(newItem);
                }
            });

            currentCartItems[indexTable].carts = result;
            return currentCartItems;
        });
    };

    const addToCart = (item: ItemType) => {
        const index = parseInt(searchParams.get('cartIndex') || '0');
        let newCartItems;
        if (!cartItems[indexTable]?.carts) {
            newCartItems = getInitialCartState('1');
        } else {
            if (cartItems[indexTable]?.carts.length === 0) {
                newCartItems = getInitialCartState('1');
            } else {
                newCartItems = [...cartItems[indexTable].carts];
            }
        }
        let total = newCartItems[index]?.prices?.new_items_total?.value || 0;

        const hasSimpleProduct = newCartItems[index]?.items?.some?.(
            (item) =>
                item.product.__typename !== 'TicketProduct' &&
                item.status !== 'cancel',
        );
        const hasTicketProduct = newCartItems[index]?.items?.some?.(
            (item) => item.product.__typename === 'TicketProduct',
        );

        if (
            (hasSimpleProduct && item.product.__typename === 'TicketProduct') ||
            (hasTicketProduct && item.product.__typename !== 'TicketProduct')
        ) {
            if (
                hasSimpleProduct &&
                item.product.__typename === 'TicketProduct'
            ) {
                notification.warning({
                    description:
                        'You cannot add a ticket to a cart that already contains a product',
                    message: 'Warning',
                    placement: 'topRight',
                    duration: 3,
                });
                return;
            } else {
                notification.warning({
                    description:
                        'You cannot add a product to a cart that already contains a ticket',
                    message: 'Warning',
                    placement: 'topRight',
                    duration: 3,
                });
                return;
            }
        }

        const foundIndex = newCartItems[index]?.items?.findIndex(
            (i) =>
                i.id == item.id &&
                JSON.stringify(i.bundle_options) ===
                    JSON.stringify(item.bundle_options) &&
                JSON.stringify(i.product_options) ===
                    JSON.stringify(item.product_options) &&
                i.seat === item.seat &&
                i?.note === item?.note,
        );

        if (foundIndex !== -1 && foundIndex !== undefined) {
            newCartItems[index].items[foundIndex].quantity += item.quantity;
        } else {
            if (newCartItems[index]?.items) {
                newCartItems[index] = {
                    ...newCartItems[index],
                    items: [...newCartItems[index].items, item],
                };
            }
        }
        total += item.prices.price?.value * item.quantity;
        newCartItems[index].prices = {
            ...newCartItems?.[index]?.prices,
            new_items_total: {
                value: total,
            },
        };

        const newCartTable = [...cartItems];
        if (!cartItems[indexTable]?.carts) {
            setCartItems([
                {
                    tableId: tableId,
                    carts: newCartItems,
                },
            ]);
        } else {
            newCartTable[indexTable].carts = newCartItems;
            setCartItems(newCartTable);
        }
    };
    const removeItemFromCart = (sku: string) => {
        const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
        const newCartItems = [...cartItems[indexTable].carts];
        const index = newCartItems[cartIndex].items.findIndex(
            (e) => e.id === sku,
        );
        let total = newCartItems[cartIndex].prices?.new_items_total?.value || 0;
        total -= newCartItems[cartIndex]?.items[index]?.prices?.price?.value;
        newCartItems[cartIndex].items.splice(index, 1);
        newCartItems[cartIndex].prices = {
            ...newCartItems[cartIndex].prices,
            new_items_total: {
                value: total,
            },
        };
        const newCartTable = [...cartItems];
        newCartTable[indexTable].carts = newCartItems;
        setCartItems(newCartTable);
    };

    const onRemoveItem = async (index: number) => {
        setIdx(index);
        setIsModalConfirm(true);
    };
    const onRemoveItemWithoutConfirm = async (index: number) => {
        RemoveItemFromCartIndex(index);
    };
    const RemoveItemFromCartIndex = (index: number) => {
        {
            const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
            const newCartItems = [...cartItems[indexTable].carts];
            let total =
                newCartItems[cartIndex].prices?.new_items_total?.value || 0;
            if (newCartItems[cartIndex]?.items[index]?.status !== 'cancel') {
                total -=
                    newCartItems[cartIndex]?.items[index]?.prices?.price
                        ?.value * newCartItems[cartIndex].items[index].quantity;
            }
            newCartItems[cartIndex].items.splice(index, 1);
            newCartItems[cartIndex].prices = {
                ...newCartItems[cartIndex].prices,
                new_items_total: {
                    value: total,
                },
            };
            const newCartTable = [...cartItems];
            newCartTable[indexTable].carts = newCartItems;
            setCartItems(newCartTable);
        }
    };
    const customOpenPriceForItem = ({
        index,
        custom_price,
    }: {
        index: number;
        custom_price: number;
    }) => {
        const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
        const newCartItems = [...cartItems[indexTable].carts];
        const old_price =
            newCartItems[cartIndex].items[index].prices.price?.value;
        const quantity = newCartItems[cartIndex].items[index].quantity;
        newCartItems[cartIndex].items[index].custom_price = parseFloat(
            `${custom_price}`,
        );
        newCartItems[cartIndex].items[index].prices.price.value = parseFloat(
            `${custom_price}`,
        );
        // help me update total price
        let total = newCartItems[cartIndex].prices?.new_items_total?.value || 0;
        if (total > 0 && total >= old_price * quantity) {
            total -= old_price * quantity;
        }
        total += custom_price * quantity;
        newCartItems[cartIndex].prices = {
            ...newCartItems[cartIndex].prices,
            new_items_total: {
                value: total,
            },
        };

        const newCartTable = [...cartItems];
        newCartTable[indexTable].carts = newCartItems;
        setCartItems(newCartTable);
    };

    const updateQuantityItemFromCart = ({
        index,
        type,
        value,
    }: {
        index: number;
        type: 'increa' | 'decrea';
        value?: number;
    }) => {
        const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
        const newCartItems = [...cartItems[indexTable].carts];
        let total = newCartItems[cartIndex].prices?.new_items_total?.value || 0;
        const cartItemExisted = newCartItems[cartIndex]?.items?.filter(
            (item, idx) =>
                item.id === newCartItems[cartIndex]?.items[index].id &&
                item.isUnsend &&
                idx === index,
        )?.[0];
        if (type === 'decrea' && cartItemExisted.quantity === 1) {
            onRemoveItem(index);
            return;
        } else {
            // Total prices

            if (value) {
                total =
                    total -
                    newCartItems[cartIndex].items[index].prices.price?.value *
                        newCartItems[cartIndex].items[index].quantity +
                    newCartItems[cartIndex].items[index].prices.price?.value *
                        value;
            } else {
                if (value === 0) {
                    onRemoveItem(index);
                    return;
                }
                if (type === 'decrea') {
                    total -=
                        newCartItems[cartIndex].items[index].prices.price
                            ?.value;
                } else {
                    total +=
                        newCartItems[cartIndex].items[index].prices.price
                            ?.value;
                }
            }

            // Total quantity
            if (cartItemExisted) {
                const _newCartItems = newCartItems[cartIndex].items?.map(
                    (item, idx) => {
                        if (
                            item.id === cartItemExisted.id &&
                            (item?.isUnsend || item?.status === 'new') &&
                            idx === index
                        ) {
                            return {
                                ...item,
                                quantity: value
                                    ? value
                                    : type === 'decrea'
                                      ? cartItemExisted.quantity - 1
                                      : cartItemExisted.quantity + 1,
                            };
                        }
                        return item;
                    },
                );
                newCartItems[cartIndex].items = _newCartItems;
            } else {
                newCartItems[cartIndex].items.push({
                    ...newCartItems[cartIndex].items[index],
                    isUnsend: true,
                    quantity: 1,
                    status: 'new',
                });
            }
        }
        newCartItems[cartIndex].prices = {
            ...newCartItems[cartIndex].prices,
            new_items_total: {
                value: total,
            },
        };
        const newCartTable = [...cartItems];
        newCartTable[indexTable].carts = newCartItems;
        setCartItems([...newCartTable]);
    };
    const InputNoteItemFromCart = (index: number, note: string) => {
        const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
        const newCartItems = [...cartItems[indexTable].carts];
        newCartItems[cartIndex].items[index].note = note;
        const newCartTable = [...cartItems];
        newCartTable[indexTable].carts = newCartItems;
        setCartItems(newCartTable);
    };
    const updateProductModifierItemFromCart = (
        index: number,
        productOptions: ProductOption[],
        price: number,
    ) => {
        const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
        const newCartItems = [...cartItems[indexTable].carts];

        let total = newCartItems[cartIndex].prices?.new_items_total?.value || 0;
        total -=
            newCartItems[cartIndex]?.items[index]?.prices?.price?.value *
            newCartItems[cartIndex].items[index].quantity;
        total += price * newCartItems[cartIndex].items[index].quantity;
        newCartItems[cartIndex].items[index].product_options = productOptions;
        newCartItems[cartIndex].items[index].prices.price.value = price;

        newCartItems[cartIndex].prices = {
            ...newCartItems[cartIndex].prices,
            new_items_total: {
                value: total,
            },
        };

        const newCartTable = [...cartItems];
        newCartTable[indexTable].carts = newCartItems;
        setCartItems(newCartTable);
    };
    const UpdateGuestInfoOfCart = (input: {
        name: string;
        note: string;
        phone: string;
        numberOfCustomer: number;
        serve_name: string;
    }) => {
        const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
        const newCartItems = [...cartItems[indexTable].carts];
        newCartItems[cartIndex].firstname = input.name;
        newCartItems[cartIndex].note = input.note;
        newCartItems[cartIndex].phonenumber = input.phone;
        newCartItems[cartIndex].numberOfCustomer = input.numberOfCustomer;
        newCartItems[cartIndex].serve_name = input.serve_name;
        const newCartTable = [...cartItems];
        newCartTable[indexTable].carts = newCartItems;
        setCartItems(newCartTable);
    };
    const InputNoteItemBundleFromCart = (
        index: number,
        note: string,
        bundleIdex: number,
    ) => {
        const cartIndex = parseInt(searchParams.get('cartIndex') || '0');
        const newCartItems = [...cartItems[indexTable].carts];
        const check = newCartItems[cartIndex].items[index].bundle_options;
        if (check && check.length > 0) {
            check[bundleIdex].note = note;
        }
        newCartItems[cartIndex].items[index].bundle_options = check;
        const newCartTable = [...cartItems];
        newCartTable[indexTable].carts = newCartItems;
        setCartItems(newCartTable);
    };
    const setCustomerName = (
        name: string,
        selectedCart: number,
        indexTable: number,
        numberOfCustomer = 1,
        phonenumber?: string,
    ) => {
        const newCartItems = [...cartItems[indexTable].carts];
        newCartItems[selectedCart].firstname = name;
        newCartItems[selectedCart].numberOfCustomer = numberOfCustomer;
        newCartItems[selectedCart].phonenumber = phonenumber;
        const newCartTable = [...cartItems];
        newCartTable[indexTable].carts = newCartItems;
        setCartItems(newCartTable);
    };
    const cartContextValue: CartContextType = {
        indexTable,
        cartItems,
        addCart,
        addToCart,
        updateQuantityItemFromCart,
        // clearCart,
        selectedCart,
        setSelectedCart,
        setCustomerName,
        updateCartIndex,
        removeCartIndex,
        removeItemFromCart,
        InputNoteItemFromCart,
        InputNoteItemBundleFromCart,
        onRemoveItem,
        customOpenPriceForItem,
        onRemoveItemWithoutConfirm,
        UpdateGuestInfoOfCart,
        cartIndex,
        tableId,
        NewSeat,
        RemoveSeat,
        setSeat,
        seat,
        group,
        setGroup,
        setListSeat,
        updateProductModifierItemFromCart,
    };
    const [isModalConfirm, setIsModalConfirm] = React.useState<boolean>(false);
    const [idx, setIdx] = React.useState<any>('');
    const onCancel = () => {
        setIsModalConfirm(false);
    };
    const onSubmit = () => {
        setIsModalConfirm(false);
        RemoveItemFromCartIndex(idx);
    };
    return (
        <CartContext.Provider value={cartContextValue}>
            <ModalConfirm
                isModalOpen={isModalConfirm}
                onCancel={onCancel}
                onSubmit={onSubmit}
                title="Remove this item?"
                content="Do you want to this item remove?"
            />
            {children}
        </CartContext.Provider>
    );
};

export const getInitialTableState = (id: string, tableId: string | number) => {
    return {
        tableId: tableId,
        carts: [
            {
                id: id,
                items: [],
                applied_coupons: null,
                customerName: 'Guest',
                prices: {
                    discounts: [
                        {
                            amount: {
                                value: 0,
                            },
                            label: '',
                        },
                    ],
                    grand_total: {
                        value: 0,
                    },
                },
            },
        ],
    };
};

export const getInitialCartState = (id: string) => {
    return [
        {
            is_active: true,
            id: id,
            items: [],
            applied_coupons: null,
            numberOfCustomer: 1,
            is_local: true,
            firstname: `Guest ${id}`,
            prices: {
                discounts: [
                    {
                        amount: {
                            value: 0,
                        },
                        label: '',
                    },
                ],
                grand_total: {
                    value: 0,
                },
                amount_due: {
                    value: 0,
                },
                new_items_total: {
                    value: 0,
                },
            },
        },
    ];
};
